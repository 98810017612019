var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "mt-4" }, [
    _c(
      "div",
      { staticClass: "new-history" },
      [
        _c("h2", { staticClass: "font-bold" }, [
          _vm._v("Histórico de Callbacks")
        ]),
        _vm.historico.length > 0
          ? [
              _c(
                "vs-table",
                {
                  attrs: {
                    data: _vm.historico,
                    hoverFlat: true,
                    noDataText: "Nenhuma histórico encontrado"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(item, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr, attrs: { data: item } },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-left",
                                    attrs: { data: data[indextr].Id }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.TransactionId || "-") +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-left",
                                    attrs: { data: item.Origin }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.Status) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-left",
                                    attrs: { data: item }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            item.TransactionDate,
                                            "DD/MM/YYYY HH:mm"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ],
                    null,
                    false,
                    2423436153
                  )
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Identificação da consulta")]),
                      _c("vs-th", [_vm._v("Status")]),
                      _c("vs-th", { staticClass: "text-left" }, [
                        _vm._v("Data da consulta")
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ]
          : [_vm._m(0)]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32"
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description")
          ])
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v("Nenhum registro encontrado!")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }